// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/usr/home/Mateo1907/domains/milap.smartapps.com.pl/public_nodejs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/usr/home/Mateo1907/domains/milap.smartapps.com.pl/public_nodejs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-firmie-js": () => import("/usr/home/Mateo1907/domains/milap.smartapps.com.pl/public_nodejs/src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/usr/home/Mateo1907/domains/milap.smartapps.com.pl/public_nodejs/.cache/data.json")

